// Dependencies
import React from "react";
import styled from "@emotion/styled";

// Styles
import dimensions from "styles/dimensions";

// Components
import Layout from "components/Layout";
import BackButton from "components/ui/BackButton";

// Local images
import construction from "images/construction.png";

// Main container
const Content = styled("div")`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 2em;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        flex-direction: column;
    }
`

// Text
const Text = styled("div")`
    width: 50%;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        width: 100%;
    }
`

// Image
const Image = styled("div")`
    width: 25%;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        width: 50%;
    }

    img {
        width: 100%;
    }
`

const NotFoundPage = () => (
    <Layout>
        <Content>
            <Text>
                <h2>404 Not Found</h2>
                
                <p>
                    Sorry, this page doesn't seem to exist!
                </p>

                <BackButton toPage={"/"} />
            </Text>

            <Image>
                <img src={construction} alt="Confused cartoon"></img>
            </Image>
        </Content>
    </Layout>
)

export default NotFoundPage;