// Dependencies
import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";

// Styles
import colors from "styles/colors";
import dimensions from "styles/dimensions";


// Back button
const BackButton = styled("div")`    
    transition: all 125ms ease-in-out;
    display: inline-block;
    margin-top: ${(props) => props.marginTop || "1.5em"};
    line-height: 0;

    /* Styling links */
    a {
        text-decoration: none;
        transition: all 125ms ease-in-out; 
        color: ${colors.bqblue500};

        &:hover {
            cursor: pointer;
            transition: all 125ms ease-in-out;
            color: ${colors.bqblue600};    
            background-color: ${colors.bqblue200};

            span {
                transform: translateX(0px);
                transition: transform 125ms ease-in-out;
            }
        }
    }

    @media (max-width: ${dimensions.maxwidthMobile}px) {
        font-size: 1.25em;
    }

    span {
        margin-right: 0.75em;
        transform: translateX(6px);
        display: inline-block;
        transition: transform 250ms ease-in-out;
    }
`

export default ({ toPage, marginTop }) => (
    <BackButton marginTop={marginTop}>
        <Link to={toPage}>
            <span>&#8592;</span>Back
        </Link>
    </BackButton>
);